import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="my-5">Terms & Conditions</h1>
            <h4>
              Effective:<span>September 9, 2022</span>
            </h4>
            <p>
              These Terms and Conditions (“Terms“) set forth the terms and
              conditions Dilivva Limited, a Nigerian Company (“Dilivva”)
              requires you to accept if you want to download, install, access or
              use the Dilivva platform (“Platform”) via our website at{" "}
              <a href="www.dilivva.com">www.dilivva.com</a> (“Website”) and/or
              via any associated application or API (“App”) in order to receive
              Delivery Services from, or perform Delivery Services for, other
              Users of the Platform. These Terms whether stated or otherwise
              referenced herein constitute a legal agreement between you and
              Dilivva. By using or accessing the Dilivva Platform in any manner,
              including downloading, installing or using any App, or receiving
              or performing any Delivery Services, you hereby expressly
              acknowledge and agree to be bound by these Terms, and any future
              amendments and additions to these Terms as published from time to
              time at our Website at{" "}
              <a href="https://www.dilivva.com/terms">
                https://www.dilivva.com/terms
              </a>{" "}
              or on the App. Upon accessing or using the Dilivva Platform, or
              receiving or performing Delivery Services you become a “User” of
              Dilivva’s Platform, whether as a “Partner” or a “Sender“, as
              further described herein.
            </p>
            <p>
              These Terms expressly include the{" "}
              <a href="../Partner-agreement/">Partner's Agreement</a> and Sender's Agreement
              which are incorporated into these Terms by this reference. These
              Agreements set forth the specific obligations you must agree to in
              order to use the Platform as a Partner or a Sender and you agree to
              be bound by the applicable Agreement upon accepting these Terms.
            </p>
            <p>
              You may only access the Platform using the Website, an App or
              other specifically authorized means. It is your responsibility to
              check to ensure you download the correct App for your mobile,
              tablet, computer or similar device (“Device“). Dilivva is not
              liable if you do not have a compatible Device or if you have
              downloaded the wrong version of the App for your Device. Dilivva
              reserves the right to deactivate your account and your ability to
              use our Platform if you access the Platform with the wrong version
              of the App or an incompatible or unauthorized Device.
            </p>
            <p>
              Dilivva reserves the right to modify the terms and conditions of
              these Terms or any of its policies relating to our Platform or the
              accessing of Delivery Services by our Users thereon at any time,
              effective upon posting of an updated version of the Terms on our{" "}
              <a href="https://dilivva.com">Website</a> on our App. You are
              responsible for regularly reviewing these places for any updates
              to the Terms. Use of our Platform after any updates to these Terms
              shall constitute your consent to all such changes made to the
              Terms in such update and your affirmation of your agreement to the
              updated Terms as a whole.
            </p>
            <br />
            <h2>OUR SERVICES UNITE PARTNERS AND SENDERS</h2>
            <p>
              Our Platform makes possible a connection between those individuals
              and/or businesses (“Senders“) that want to contract with someone
              to pick-up, carry and/or deliver personal property and goods
              (“Delivery Services“) and those individuals and/or businesses
              seeking to perform the Delivery Services (“Partners“). The
              performance of Delivery Services by a Partner, the time period in
              which these Delivery Services are performed by a Partner, as well
              as the item(s) of personal property and goods being picked-up,
              carried and/or delivered by a Partner, are collectively referred to
              as a “Gig”. Partners and Senders together are referred to as
              "Users" of our Platform.
            </p>
            <p>
              Our Platform connects third party Partners who want to deliver Gigs
              for third party Senders, and gives those Senders a way to find
              those Partners and schedule their Gigs. Partners are self-employed
              individuals and independent contractors who choose to perform
              Delivery Services for Senders posting Gigs on our Platform. At no
              time does Dilivva control whether or when a Sender posts a
              particular Gig, or whether or when a Partner chooses to offer for a
              particular Gig. Agreeing to perform Delivery Services for Senders
              through the Dilivva Platform does not preclude a Partner from
              providing Delivery Services, or any other types of services, under
              another platform or for other persons simultaneously.
            </p>
            <h5>
              DILIVVA IS A LOGISTICS MANAGEMENT PLATFORM AND DOES NOT PROVIDE
              TRANSPORTATION SERVICES.
            </h5>
            <h5>
              WE ARE NOT A TRANSPORTATION CARRIER. IT IS THE PARTNER’S SOLE
              DECISION WHETHER TO OFFER FOR AND PROVIDE THE DELIVERY SERVICES
              REQUESTED BY A SENDER.
            </h5>
            <p>
              Our Platform enables connections between our Users to contract for
              Gigs; however, Dilivva has no control over and is not responsible
              for the performance, actions or inactions of any User, whether
              associated with the use of our Platform or the receiving or
              providing the Delivery Services via our Platform, in public,
              private, or offline interactions, or otherwise. That’s why we rely
              on YOU, our Users, to follow some rules to keep the Dilivva
              community safe, friendly and helpful for all!
            </p>
            <h4>User Representations and Warranties</h4>
            <p>
              Most importantly, all Users need to know that every time you
              access or use the Platform or receive or perform Delivery Services
              through the Platform, you expressly agree, represent and warrant
              that, at the time of each such access or use:
            </p>
            <ul>
              <li>
                You are legally entitled to and have the right, authority and
                capacity to enter into the agreements set forth in these Terms
                and to fulfill your obligations here under.
              </li>
              <li>
                Your use of the Platform is for your sole, personal use and you
                will not resell to a third party.
              </li>
              <li>
                You are at least 18 years of age or such older age as may be
                required in a State or other jurisdiction that restricts the
                ability to enter into agreements due to age. If you are not the
                required age you must not use the Platform and you may not
                perform Delivery Services.
              </li>
              <li>You will only create one User account.</li>
              <li>
                You will keep secure and confidential your User account password
                or any identification we provide you which allows access to our
                Platform.
              </li>
              <li>
                You will provide Dilivva with such proof of identity we
                reasonably request and will not impersonate any person or
                entity, or falsify or otherwise misrepresent yourself or your
                affiliation (actively or by omission) with any person or entity,
                including Partners must use a true facial picture in their
                profile when creating a Dilivva account.
              </li>
              <li>
                You will not represent yourself to be an agent, subcontractor,
                representative, employee or affiliate of Dilivva.
              </li>
              <li>
                You will only access and use the Platform and receive or perform
                Delivery Services for lawful purposes and in accordance with all
                applicable laws and regulations.
              </li>
              <li>
                You will not access or use the Platform, or receive or perform
                Delivery Services for shipping, sending or storing any unlawful
                material, for fraudulent purposes, for promoting or encouraging
                any illegal activity, or for committing or assisting in the
                commission of a crime.
              </li>
              <li>
                You will keep in confidence and shall not disclose, or use for
                the benefit of others, any and all third parties’ confidential,
                proprietary, or personally identifiable and/or personal health
                information including that of Dilivva personnel, or any Sender
                (or their personnel), Partner (or their personnel), Gig recipient
                (or their personnel), or other Users of the platform and of
                which you may become aware as a User, except as necessary to
                carry out and perform under these Terms.
              </li>
              <li>
                You will not collect or store any information about any Dilivva
                personnel, or any Sender (or their personnel), Partner (or their
                personnel), Gig recipient (or their personnel), or other Users
                of the platform.
              </li>
              <li>
                You will not access or use any information about any Dilivva
                personnel, or any Sender (or their personnel), Partner (or their
                personnel), Gig recipient (or their personnel), or other Users
                of the platform except only as strictly needed during the
                performance of a Gig.
              </li>
              <li>
                You will not contact any Dilivva personnel, or any Sender (or
                their personnel), Partner (or their personnel), Gig recipient (or
                their personnel), or other User directly or outside of the
                Platform. You shall not contact any Sender (or their personnel),
                Partner (or their personnel), Gig recipient (or their personnel),
                or other Users directly once the Gig is complete.
              </li>
              <li>
                You will not contact any Dilivva personnel for any personal
                reason that does not involve your use of the platform.
              </li>
              <li>
                You will not stalk, intimidate, threaten or otherwise harass or
                cause physical or mental distress to any third party, including
                Dilivva personnel, or any Sender (or their personnel), Partner
                (or their personnel), Gig recipient (or their personnel), or
                other Users of the platform and the general public.
              </li>
              <li>
                You will not copy, or distribute text, graphics, images, music,
                software, audio, video, information or other like materials
                related to our Website, Platform or App (“Content“) without
                written permission from Dilivva.
              </li>
              <li>
                You will not access or use our Platform or receive or perform
                Delivery Services in any way that could cause nuisance,
                annoyance, and inconvenience or could interfere with or
                negatively afect other Users from fully using or enjoying the
                Platform or receiving or performing Delivery Services.
              </li>
              <li>
                You will not infringe the rights of any third party (including
                other Users and Dilivva) and including, intellectual property,
                privacy, publicity or contractual rights.
              </li>
              <li>
                You will not discriminate against someone based on traits such
                as age, color, disability, gender identity, marital status,
                national origin, race, religion, sex, or sexual orientation.
              </li>
              <li>You will not assist any third-party in any of the above.</li>
            </ul>
            <h5>
              ALL CONTACT BETWEEN USERS SHOULD END WHEN THE GIG IS COMPLETE.
            </h5>
            <h5>
              TEXTING, CALLING, VISITING, OR TRYING TO VISIT SOMEONE IN PERSON
              AFTER THE GIG HAS BEEN COMPLETED IS NOT ALLOWED.
            </h5>
            <h5>
              YOU SHOULD IMMEDIATELY ALERT DILIVVA IF ANOTHER USER CONTACTS YOU
              FOR ANY REASON OTHER THAN YOUR GIG.{" "}
            </h5>
            <p>
              Keeping the Dilivva Community safe, friendly and helpful for all
              means that all Users of our Platform have a responsibility to
              treat each other with respect and dignity. That is why Dilivva
              strictly enforces its Non-Discrimination and Respect Others
              Policies as set forth below.
            </p>
            <h4>Dilivva Non-Discrimination Policy</h4>
            <p>
              Dilivva and its affiliates prohibit discrimination against any
              User or other third party based on race, religion, national
              origin, disability, sexual orientation, sex, marital status,
              gender identity, age or any other characteristic protected under
              applicable law. Dilivva wants all of our Users to feel safe and
              welcome and we do not tolerate any conduct that we determine to be
              discriminatory.
            </p>
            <p>
              Such discrimination includes, but is not limited to, intentionally
              refusing or canceling Gigs solely for the purpose of avoiding a
              particular neighborhood due to the characteristics of the people
              or businesses that are located in that area or because you are
              uncomfortable with certain persons based on traits such as age,
              color, disability, gender identity, marital status, national
              origin, race, religion, sex, or sexual orientation.
            </p>
            <h4>Dilivva Respect Others Policy</h4>
            <p>
              Dilivva prohibits all Users from displaying any aggressive,
              confrontational, intimidating, threatening or harassing behavior
              toward others, including without limitation:
            </p>
            <ul>
              <li>
                Hitting, hurting, or intending to hurt any person or animal, or
                threatening to do the same. Using language or making gestures
                that could be disrespectful, harassing or threatening.
              </li>
              <li>
                Sexual assault or sexual harassment or misconduct of any kind.
                Assault includes any intentional bodily contact made without
                explicit consent of the other person, and sexual harassment and
                misconduct, includes without limitation unconsented to advances,
                behaviors and remarks like nudging, whistling, winking or
                flirting, discussing your own or someone else’s sex life, using
                explicit language, or making jokes about sex.
              </li>
              <li>
                Disrespecting personal space and/or privacy, like standing
                unnecessarily close, or commenting on personal appearance,
                perceived gender identity, or sexual orientation, or asking
                unrelated personal questions.
              </li>
            </ul>
            <h5>
              ANY USER FOUND TO HAVE VIOLATED DILIVVA’S NON-DISCRIMINATION
              POLICY OR ITS RESPECT OTHERS POLICY WILL IMMEDIATELY LOSE ALL
              ACCESS TO THE DILIVVA PLATFORM.
            </h5>
            <h4>Consumer Report and/or Investigative Consumer Reports</h4>
            <p>
              After a Partner forms an account on the Dilivva App and is
              conditionally granted the right to access and use the Dilivva App
              Dilivva will obtain your consumer report and/or investigative
              consumer report from a third-party Credit Reporting Agency
              (“CRA”). During registration as a Partner, you are required to
              provide a valid Partner’s license and Social Security Number, and
              your written consent to Dilivva obtaining your consumer report
              and/or investigative consumer report at that time, prior to
              allowing you to perform Gigs, and/or at any time thereafter, as
              and when determined in our sole discretion, and without any
              additional consent from you, as may be allowed pursuant to
              applicable law. The consumer report and/or investigative consumer
              report does not include a credit check.
            </p>
            <p>
              In order to be eligible to perform certain Gigs and to help
              protect against theft and fraud, Partners may be asked to submit
              additional personal information to verify their identity,
              including biometric information or identifiers such as a selfie,
              scan of their Partner’s license and/or other government ID or
              photo, scan of their face, facial patterns, fingerprints, and
              voice or typing cadence (“biometric data”).
            </p>
            <p>
              Dilivva uses various third party technology, tools and services to
              collect your personal information and biometric data from you. For
              more information on how your personal information and biometric
              data are used and disclosed, please see our Privacy Policy at
              https://dilivva.com/privacy-policy.
            </p>
            <h5>
              ALL USERS ACKNOWLEDGE AND AGREE THAT BY ACCESSING AND USING OUR
              PLATFORM TO RECEIVE OR PERFORM DELIVERY SERVICES, THEY MAY BE
              EXPOSED TO UNKNOWN THIRD-PARTIES AND CONTENTS IN GIGS THAT MAY BE
              OR ARE POTENTIALLY DANGEROUS, OFFENSIVE, HARMFUL, UNSAFE OR
              OTHERWISE OBJECTIONABLE.
            </h5>
            <h4>Related FAQ</h4>
            <ul>
              <li>
                What does a consumer report and/or investigative consumer report
                include?
              </li>
              <li>
                What if my consumer report and/or investigative consumer report
                is inaccurate or incomplete?
              </li>
              <li>
                Why do I have to take a photo of my ID and take a selfie during
                sign up?
              </li>
              <li>
                How does Dilivva use, disclose, secure and/or store my biometric
                data and personal information?
              </li>
            </ul>
            <h3>PARTNERS’ RESPONSIBILITIES</h3>
            <h4>Generally</h4>
            <p>
              It is every Partner’s duty and obligation to know and comply with
              these Terms and the Partners Agreement found here:{" "}
              <a href="https://dilivva.com/partner-agreement">
              https://dilivva.com/partner-agreement
              </a>{" "}
              contained herein and all applicable federal and state laws
              relating to the items included in any Gig they offer to perform.
            </p>
            <h4>Permits, Auto Insurance and Health Insurance</h4>
            <p>
              Because Partners act as their own employer in providing Delivery
              Services via the Dilivva Platform, Dilivva does not, and has no
              responsibility to, provide or pay for any permit, license or
              insurance a Partner may need or that may be advisable, to perform
              Delivery Services via the Dilivva Platform. Many states require a
              Partner to have permits and/or licenses to carry certain Gigs.
              Additionally, many insurance companies will require a Partner to
              have some level of commercial insurance if using a personal
              vehicle for business purposes and all Partners should be aware that
              their standard automobile liability insurance may not provide them
              coverage while they are engaged in performing a Gig or while or
              logged into the App.
            </p>
            <p>
              Dilivva does not provide health insurance, or any other
              compensation to Partners if they are hurt or injured while
              performing a Gig and Dilivva does not carry workers’ compensation
              insurance for any Partner unless specifically required by State
              law. For these reasons, all Partners are required to have adequate
              health insurance prior to performing a Gig. Only you can know if
              you are physically capable of handling a Gig you agree to deliver
              and Dilivva is not liable to you for any costs or damages you may
              suffer if you are hurt or injured while performing a Gig. At all
              times you are solely responsible for the costs of any medical care
              you may require due to any such injury.
            </p>
            <p>
              It is a Partners’ responsibility to know what auto insurance,
              health insurance, licenses and permits are required or recommended
              and to procure the same prior to offering on Gigs. A Partner must
              have valid auto insurance in such amounts as required by State Law
              and its insurer to cover Partner’s performance of Gigs through the
              Dilivva Platform PRIOR to offering on Gigs. A Partner will not be
              allowed to deliver Gigs and will be removed from the Platform if
              the Partner does not have auto insurance coverage in at least the
              minimum amounts required by the State in which the Partner provides
              Delivery Services. A Partner must carry proof of all required auto
              insurance coverage at all times while performing Gigs and must
              provide the same to Dilivva and/or other authorities and officials
              upon request.
            </p>
            <h5>
              A PARTNER MUST HAVE THE REQUIRED INSURANCE, LICENSES AND PERMITS TO
              CARRY ALL ITEMS CONTAINED IN GIG.
            </h5>
            <h5>
              AS A PARTNER USING THE DILIVVA PARTNER APPLICATION, YOU ACKNOWLEDGE 
              AND AGREE THAT DILIVVA AFRICA DIGITAL TECH LIMITED MAY COLLECT AND 
              TRACK YOUR LOCATION INFORMATION USING GPS OR OTHER LOCATION-BASED TECHNOLOGIES 
              WHILE THE APPLICATION IS RUNNING IN THE BACKGROUND OR FOREGROUND. THIS LOCATION 
              INFORMATION WILL BE USED TO ENSURE SUCCESSFUL DELIVERY OF PACKAGES AND TO IMPROVE 
              THE QUALITY OF OUR SERVICES. WE WILL USE THIS INFORMATION SOLELY FOR THE PURPOSES DESCRIBED 
              IN OUR PRIVACY POLICY, AND WE WILL NOT DISCLOSE YOUR LOCATION INFORMATION TO THIRD PARTIES 
              WITHOUT YOUR CONSENT, EXCEPT AS REQUIRED BY LAW. BY USING THE DILIVVA PARTNER APPLICATION, 
              YOU CONSENT TO OUR COLLECTION AND USE OF YOUR LOCATION INFORMATION AS DESCRIBED IN THESE TERMS 
              AND CONDITIONS AND IN OUR PRIVACY POLICY.
            </h5>
            <h5>
              ALL PARTNER SHOULD CHECK WITH PROFESSIONALS TO ENSURE THAT YOU
              HAVE THE REQUIRED INSURANCE, LICENSES AND PERMITS BEFORE YOU OFFER
              FOR A GIG.
            </h5>
            <h5>
              DILIVVA HAS NO RESPONSIBILITY OR LIABILITY FOR ANY PARTNER OR
              SENDER NOT HAVING THE PROPER AUTHORITY, PERMITS, LICENSES OR
              INSURANCE TO ENTER INTO THE TRANSACTIONS AGREED UPON ON THE
              PLATFORM.
            </h5>
            <h4>Confidential Information</h4>
            <p>
              During the performance of Gigs, Partners will have access to the
              “Confidential Information” of many third parties, including
              without limitation, the Confidential Information of Dilivva,
              Senders, and/or Gig recipients. “Confidential Information” means
              all information, whether oral, written, contained on electronic
              media or otherwise, to which a Partner is given or has access to,
              or is made available to the Partner in connection with the
              performance of Gigs, including without limitation names,
              addresses, telephone numbers and other information which may be
              used to specifically identify a person (“PII” or “Personally
              Identifiable Information”), and health information protected under
              the Health Insurance Portability and Accountability Act (“HIPPA”
              and “Protected Health Information”).
            </p>
            <p>
              Confidential Information shall include, without limitation, all
              information regarding Dilivva’s personnel or customer information
              of Dilivva, Senders and/or Gig recipients of which a Partner
              becomes aware.
            </p>
            <p>
              Dilivva and Partners have special obligations with respect to the
              protection of Personally Identifiable Information and Protected
              Health Information and a Partner is responsible for both knowing
              those obligations and complying with the same. Failure to meet
              these obligations will result in the Partner’s permanent removal
              from the Dilivva Platform and the Partner shall be prosecuted to
              the fullest extent of the law.
            </p>
            <p>
              <strong>Personally Identifiable Information:</strong> Personally
              Identifiable Information (PII) refers to information that can be
              used to distinguish or trace an individual’s identity, either
              alone or when combined with other personal or identifying
              information that is linked or linkable to a specific individual.
              It is important to recognize that non-PII can become PII whenever
              additional information is made available — in any medium and from
              any source — that, when combined with other available information,
              could be used to identify an individual. Dilivva and Partners are
              under a strict obligation not to use or disclose PII except as
              necessary to perform and deliver Gigs.
            </p>
            <p>
              <strong>Protected Health Information:</strong> If a Partner delivers
              Gigs containing prescriptions, medicines or other medical
              documents, the Partner may have access to Protected Health
              Information. While Senders should not be making Protected Health
              Information accessible to Partners, if such information is accessed
              inadvertently, Partners are under a strict obligation not to use or
              disclose it in any manner.
            </p>
            <h5>
              BY USING THE DILIVVA PLATFORM TO RECEIVE OR PERFORM DELIVERY
              SERVICES YOU MAY AND/OR WILL GAIN KNOWLEDGE OF THIRD PARTIES’
              CONFIDENTIAL, PROPRIETARY, PERSONALLY IDENTIFIABLE AND/OR
              PROTECTED HEALTH INFORMATION.
            </h5>
            <h5>
              EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOU, AS A USER OF THE DILIVVA
              PLATFORM ARE SUBJECT TO A STRICT DUTY TO MAINTAIN THE ABSOLUTE
              CONFIDENTIALITY OF ALL SUCH CONFIDENTIAL AND PERSONAL INFORMATION
              AND YOU UNDERSTAND THAT UNDER NO CIRCUMSTANCES MAY YOU DISCLOSE
              SUCH INFORMATION TO ANY OTHER PERSON OR USE SUCH INFORMATION FOR
              ANY OTHER REASON THAN TO RECEIVE OR PERFORM DELIVERY SERVICES VIA
              THE DILIVVA PLATFORM.
            </h5>
            <h4>Partner Teams and Partner Personnel</h4>
            <p>
              To the extent permitted by law and subject to these Terms, Partners
              may work in teams (“Partner Teams”) and/or may hire or engage
              others as employees or subcontractors (collectively “Partner
              Personnel”) to perform all or some of the Delivery Services to be
              provided by the Partner, provided all Partner Team members and all
              Partner Personnel are registered and have formed an account on the
              Dilivva Platform and meet all requirements applicable to Partners
              contained in the Terms including, presenting, or agreeing to
              Dilivva obtaining, their consumer report and/or investigative
              consumer report, and agreeing to these Terms and Partners
              Agreement, prior to Partner using such Partner Personnel to perform
              Delivery Services on Partner’s behalf.
            </p>
            <p>
              To the extent Partners work in a Partner Team, all Partners in such
              Team shall be jointly and severally liable for all obligations and
              liabilities arising from acts and omissions of the Partner Team in
              performing the Delivery Services. To the extent that a Partner
              provides Delivery Services using Partner Personnel, Partner shall be
              solely responsible for the direction and control of the Personnel
              it uses in the performance of Delivery Services, and solely liable
              for all obligations and liabilities arising from acts and
              omissions of Partner Personnel in performing the Delivery Services,
              as if Partner was performing such Delivery Services themself.
              Partner also assumes full and sole responsibility for the payment
              of all amounts due to their Personnel for any work performed under
              these Terms, including all wages, benefits and expenses, if any,
              and for all required state and federal income tax withholdings,
              unemployment insurance contributions, and social security taxes.
            </p>
            <p>
              The Partners Agreement provides more details regarding a Partner’s
              responsibilities and obligations with respect to the use of Partner
              Personnel.
            </p>
            <h3>SENDER RESPONSIBILITIES</h3>
            <p>
              General It is every Sender’s duty and obligation to know and
              comply with the restrictions contained herein and all applicable
              federal and state laws relating to the items included in their
              Gig. Senders have the responsibility to package items
              appropriately based on level of risk, fragility and other factors
              specific to the individual item. Dilivva is not liable for loss or
              damage to any Gig if it is not properly packed to withstand
              transport, or to ensure the safety and integrity of the item or in
              compliance with all applicable laws. Sender will be fully
              responsible for all liabilities arising from Sender’s failure to
              comply with these Terms, or all applicable laws with respect to
              the inclusion of specific items contained in a Gig.
            </p>
            <p>Limitations on Gig Contents</p>
            <p>Prohibited Items:</p>
            <p>
              Senders may not and each Sender agrees they will not request
              delivery of Gigs that include any Prohibited Item listed below:
            </p>
            <ul>
              <li>People</li>
              <li>
                Anything illegal including, without limitation, recreational
                drugs and other contraband.
              </li>
              <li>
                Hemp, hemp derived cannabidiol (“CBD”) products of any type,
                including oils, tinctures, edible products, beverages, and
                cosmetic or personal care no matter THC level, and marijuana,
                marijuana derived strains, and marijuana products (whether or
                not legal and no matter the THC level) (collectively, “CBD and
                Marijuana Products”).
              </li>
              <li>
                Beer, wine, spirits and other alcohol and liquor Products
                (“Alcohol Products”) and traditional cigarettes, cigars and
                other any product made or derived from tobacco that is intended
                for human consumption, and E-cigarettes, vape pens, tank systems
                and other electronic nicotine delivery systems (collectively
                “Tobacco and ENDS Products”) or any other product regulated and
                controlled by the United States Alcohol and Tobacco Tax and
                Trade Bureau (TTB).
              </li>
              <li>
                Any “Hazardous Material” as defined in CFR, Title 49, in any
                quantity, including compressed gases such as propane, CO2
                (carbon dioxide) and O2 (oxygen).
              </li>
              <li>
                Any Hazardous Waste, defined as a “solid waste” that meets any
                of the criteria of the hazardous waste as described in 40 C.F.R.
                § 261.3.
              </li>
              <li>
                Unset precious stones, industrial diamonds, any article that
                contains more than fifty percent by weight of gold or platinum
                or any combination thereof in raw form, including, but not
                limited to, bullion, bars, or scraps of these metals.
              </li>
              <li>
                Cremated remains, human remains, fetal remains, human body
                parts, or components thereof.
              </li>
              <li>
                Any Delivery Order to be transported over state lines (i.e.,
                interstate) or over fifty (50) miles if being delivered to and
                from a location in the State of California.
              </li>
              <li>
                Firearms or weapons of any kind, any firearm or weapon parts, or
                ammunition in any amount.
              </li>
              <li>
                Devices containing gunpowder and other combustible chemicals
                that explode to produce visual and audible effects when ignited
                (“Fireworks”), including common Fireworks and display Fireworks.
              </li>
              <li>
                Replica or inert explosives or weapons that bear an appearance
                to actual explosives or weapons.
              </li>
              <li>
                Prescription drugs and medicines or regulated over the counter
                medicine.
              </li>
              <li>
                Heavy haul, oversize or overweight items, either individually or
                collectively, weighing more than 400 , and/or individually
                exceeding more than 12 feet in any dimension (“Oversized
                Items”).
              </li>
              <li>Gun Safes: Upright, fire rated, regardless of weight.</li>
              <li>All live animals.</li>
              <li>
                No item being transported or shipped by or on behalf of any
                governmental authority.
              </li>
            </ul>
            <h5>
              DILIVVA HAS AND TAKES NO RESPONSIBILITY OR LIABILITY FOR THE
              INCLUSION OF ANY PROHIBITED ITEMS IN ANY GIG OR SENDER’S FAILURE
              TO COMPLY WITH ALL APPLICABLE LAWS.
            </h5>
            <h4>Packaging</h4>
            <p>
              Sender Open Box Policy: Dilivva encourages the use of minimal
              packaging both to help our environment and so our Partners know
              what they are taking along on their ride. When packaging is
              required or desired, Dilivva supports and strongly encourages its
              Partners and Senders to always use an Open Box Policy. Dilivva’s
              Open Box Policy means our Senders should always leave packaging
              open so that a Partner may inspect the contents before leaving with
              the Gig. If a Sender doesn’t leave the packaging open, a Partner
              always has the right to require a Sender to open packaging to
              inspect items before leaving with the Gig.
            </p>
            <p>
              Senders should know that a Partner is NEVER under an obligation to
              take or bring anything that the Partner has not been given the
              right to inspect as described and limited above and can always
              cancel the Gig without penalty if a Sender does not allow
              inspection or gives a hard time about the Partner asking to inspect
              the Gig.
            </p>
            <p>
              Packing Your Gigs Generally: Senders have the responsibility to
              package items appropriately based on level of risk, fragility and
              other factors specific to the individual item, as determined by
              the Sender. Dilivva cannot know what packaging is necessary for
              any item you send. Please use your common sense when packing your
              Gig as Dilivva is not liable for loss or damage to any Gig if it
              is not properly packed to withstand transport, or to ensure the
              safety and integrity of the item or in compliance with all
              applicable laws.
            </p>
            <h3>DILIVVA PROTECTION PLAN</h3>
            <p>
              Dilivva wants you to feel confident that your Gig is in good
              hands. With no extra fee, the Dilivva Protection Plan provides
              Senders with protection against loss, damage or theft to items in
              your Gig, up to ₦50,000* of protection per Gig.
            </p>
            <h3>CANCELATIONS AND NO-SHOWS</h3>
            <h4>Cancelation by Senders</h4>
            <p>
              If a Sender needs or wants to cancel a Gig, please cancel the
              request as soon as possible. This will notify the Partner and free
              them up to accept other Gigs from other Senders. To cancel a Gig,
              tap Cancel on the Gig Details screen within the Dilivva App, or
              Whatsapp Customer Support at <a href="tel:09158867851">+234-915-886-7851</a>.
            </p>
            <p>
              What Happens When a Sender Cancels or is a No Show? Dilivva will
              charge a Sender ₦500 for each canceled Gig and/or each no-show. A
              Gig is deemed canceled if the Sender cancels the Gig once a Partner
              is already on his way to fulfill the Gig (“Cancelation“). A Sender
              will be considered a no-show if the Sender drop offs the Gig at
              the designated pick-up or drop-off location (1) more than 15
              minutes after the agreed pick-up/drop-off time without calling,
              messaging or otherwise contacting the Partner through the Platform,
              or (2) more than 30 minutes after the agreed pick- up/drop-off
              time, whether or not Sender has contacted the Partner (“No-Show“).
              If either a Sender Cancelation or No-Show occurs, a ₦500
              cancelation fee is automatically charged to the Sender’s account.
            </p>
            <p>
              A Sender will not be charged a cancelation fee if they cancel a
              Gig due to a Partner’s No-Show, or Cancelation or if they cannot
              contact a Partner during the performance of their Gig.
            </p>
            <h4>Cancelation by Partners</h4>
            <p>
              If a Partner needs or wants to cancel a Gig, please cancel the Gig
              as soon as possible. This will notify the Sender and allow another
              Partner to accept the Gig. To cancel a Gig, tap Cancel on the Gig
              Details screen within the Dilivva App, or call Customer Support at{" "}
              <a href="tel:+2348090910573">+234-809-0910-573</a>.
            </p>
            <h3>ACTIONS LEADING TO TEMPORARY LOCKS</h3>
            <h3>AND/OR PERMANENT DEACTIVATION OF ACCOUNTS</h3>
            <p>Generally</p>
            <p>
              Without limiting any other of its remedies at law or in equity,
              Dilivva may temporarily lock and/or permanently deactivate your
              Dilivva account and access to the Platform and eliminate your
              ability to offer to perform Delivery Services if:
            </p>
            <ul>
              <li>
                you breach, are suspected of breaching, or are alleged to have
                breached these Terms or the Terms or any Partner or Sender
                Agreement to which you are subject or any of Dilivva’s other
                terms or policies governing a User’s use of the Platform;
              </li>
              <li>
                we are unable to verify or authenticate any information you
                provide to us;
              </li>
              <li>
                we believe that your actions may cause financial loss or legal
                liability for you, our Users or Dilivva, its affiliates, or
                third party providers; we believe you may subject Dilivva or you
                or any other User to regulation by any state or local government
                or regulatory agency;
              </li>
              <li>
                we suspect or it has been alleged that you have engaged in
                fraudulent, illegal or harmful activity in connection with your
                use or access of the Dilivva Platform or App, or in connection
                with your receiving or providing Delivery Services or you or
                your account is the subject of an investigation of alleged or
                suspected fraudulent, illegal or harmful activity; or
              </li>
              <li>Prohibited Items are discovered in your Gig.</li>
            </ul>
            <p>Other Actions Leading to Locked or Deactivated Accounts</p>
            <p>
              Cancelations and No Shows: Dilivva may temporarily lock your
              account after your second Cancelation or No Show and may
              permanently deactivate your account upon your third Cancelation or
              No Show.
            </p>
            <p>
              Negative Ratings: Dilivva may temporarily lock or permanently
              deactivate a Partner’s account if the Partner’s overall rating falls
              below 4-stars.
            </p>
            <p>
              Claims for Damage, Loss or Theft of Items in Gigs By a Sender or
              Recipient: Dilivva may temporarily lock a Sender’s account upon
              receiving notice that items in your Gig are missing or damaged.
              The Sender’s account will remained locked during the investigation
              of such claim. All Partners and Senders are liable for loss, theft
              or damage to items in a Gig caused by such Partner or Sender and if
              either is found to be liable for the claim, Dilivva may
              permanently deactivate the account of the Partner or Sender.
            </p>
            <p>
              Claims for Damage, Loss or Theft of Items in Gigs By a Partner:
              Dilivva will temporarily lock a Partner’s account upon receiving
              notice that items in your Gig are missing or damaged. The Partner’s
              account will remained locked during the investigation of such
              claim. If a Partner is found to have caused the claim, Dilivva may
              permanently deactivate the Partner’s account.
            </p>
            <p>
              Theft: Any Partner, Sender or recipient who steals any items in a
              Gig will be permanently deactivated immediately and prosecuted to
              the fullest extent of the law.
            </p>
            <p>
              Inactive Accounts: Dilivva reserves the right to permanently
              deactivate any User’s account that has been “inactive” for 60 days
              or more. If a Partner has not offered on a Gig within the past 60
              days, such Partner is deemed to be inactive.
            </p>
            <p>
              Reactivation of Temporarily Locked Accounts: Dilivva may
              reactivate any Partner or Sender whose account was temporarily
              locked, in its sole discretion.
            </p>
            <p>Appeal Process</p>
            <p>
              Dilivva may temporarily lock a User’s account while investigating
              any incident or action that could lead to Dilivva permanently
              deactivating the User’s account. The User’s account may be locked
              for such time as Dilivva determines is necessary to complete its
              investigation, in its sole discretion.
            </p>
            <p>
              Dilivva does not make the decision to permanently deactivate a
              Partner’s account without a complete investigation. But we also
              understand that sometimes there may be extenuating circumstances
              of which Dilivva may not be aware. Therefore, in many instances we
              allow a Partner to appeal Dilivva’s decision to permanently
              deactivate their account.
            </p>
            <p>
              Certain deactivation decisions, especially those related to zero
              tolerance violations, are not eligible for appeal. For example, we
              will not accept appeals for deactivation related to criminal
              activity while on the App, including fraud, theft or reckless
              driving. Likewise, we will not accept appeals related to
              harassment, verbal slurs or profanity directed to any individual,
              or physical or sexual altercations.
            </p>
            <p>
              Otherwise the majority of deactivation decisions will be eligible
              for appeal. Because it is impossible to anticipate the full range
              of reasons that could result in the deactivation of a Partner’s
              account, ultimate determination of eligibility will be made by
              Dilivva on a case-by-case basis. A Partner will be allowed only one
              appeal. All appeal decisions made by Dilivva are final and not
              subject to further appeal.
            </p>
            <p>
              You can find the full details of Dilivva’s appeal process in the
              email you received notifying you of the deactivation of your
              account. If you believe your deactivation is eligible for an
              appeal, please submit your appeal by following the instructions in
              your deactivation email.
            </p>
            <p>
              Should you have any questions about the appeals process please
              email us at appeals@dilivva.com.
            </p>
            <h4>Related FAQ:</h4>
            <p>How do I file an Appeal?</p>
            <p>Deactivated Accounts</p>
            <p>
              If your account is permanently deactivated, your account
              information will be deleted and you will be removed from the
              Dilivva Platform. Permanent deactivation will cause you to lose
              your user name and persona as well as any privileges, badges or
              other earned items associated with your account. If your account
              was not deactivated for cause, you may open a new account on the
              Platform. It will be considered a material breach of these Terms
              if a User who has been deactivated for cause, opens a new account
              on the Platform and such new account will be deactivated without
              notice upon discovery. Dilivva is under no obligation to
              compensate any User for any losses as a result of a User’s
              deactivation.
            </p>
            <h5>
              ALL DILIVVA ACTIONS TAKEN WITH RESPECT TO THE TEMPORARY LOCK
            </h5>
            <h5>
              AND/OR PERMANENT DEACTIVATION OF YOUR ACCOUNT MAY BE TAKEN IN
              DILIVVA’S SOLE DISCRETION,
            </h5>
            <h5>WITH OR WITHOUT NOTICE, AND WITHOUT LIABILITY TO YOU.</h5>
            <h3>BILLING AND PAYMENT</h3>
            <p>
              Dilivva is not and will not be a party to the agreements between
              Users for a Partner to perform Delivery Services using our
              Platform, including the Sender’s agreement to pay the Partner.
              Therefore, all Users of our Platform are required to provide to
              Dilivva their credit card or bank account details and such other
              information needed to vet the User and the Payment Service
              Provider retained by Dilivva (the “PSP”). By accepting these
              Terms, each User agrees that they have downloaded or printed, and
              reviewed and agreed to the PSP Agreement. Please note that Dilivva
              is not a party to the PSP Agreement and has no obligations or
              liability to any User under the PSP Agreement.
            </p>
            <h4>Payment Processing</h4>
            <p>
              Payment processing services are provided by Stripe and subject to
              the Paystack Connected Account Agreement (available at
              https://paystack.com/us/connect- account/legal), which includes
              the Stripe Services Agreement (available at
              https://paystack.com/legal) (collectively, the “Paystack Terms“).
              By using the Dilivva Platform to pay for Gigs or receive payment
              proceeds, all Users agree to be bound by the Paystack Terms, which
              may be modified from time to time. As a condition of Dilivva
              enabling payment processing services through Paystack, all Users
              authorize Dilivva to obtain all necessary access and perform all
              necessary activity on your Paystack Connected Account to
              facilitate your provision of Services as contemplated by these
              Terms and your relationship with Dilivva. All Users further agree
              to provide accurate and complete information about you and your
              business, and authorize Dilivva to share it and transaction
              information with pAYSTACK for the purposes of facilitating of the
              payment processing services provided by Stripe. Dilivva reserves
              the right to switch payment processing vendors or use alternate or
              backup vendors in its discretion.
            </p>
            <h4>Express Pay</h4>
            <p>
              Partners may elect to elect to receive payments through Dilivva’s
              Express Pay feature (which Dilivva may offer to you in its sole
              discretion). If a Partner elects to use Express Pay you acknowledge
              that you will be charged a fee for this Express Pay service as
              disclosed by Dilivva prior to confirmation. You understand that
              (i) your financial institution may not support Express Pay, (ii)
              neither Dilivva nor Paystack make any guarantee concerning how
              quickly Express Pay payments will settle with your account, and
              (iii) not all payments through the Dilivva Platform are eligible
              for Express Pay. If a Partner elects to use any direct debit
              features made available by Dilivva or its third party partners you
              acknowledge that you may be subject to supplemental agreements
              related to those services.
            </p>
            <h4>Billing</h4>
            <p>
              Senders will be responsible for paying the invoice for each Gig
              contracted for (the “Invoice”), which will include (i) the pricing
              terms of the Gig provided to a Partner, (ii) reimbursement of tolls
              and parking fees incurred by Partner in connection with the Gig if
              such Sender has agreed to reimburse for the same; and provided
              Partner submits the same in accordance with the below section
              “Reimbursement for Parking Fees and Tolls, (iii) any cancellation,
              no-show fee or wait fees, as may be applicable to the Gig, and
              (iv) any other fee Dilivva may assess for use of its Platform
              (combined, the “Gig Payment”). Unless otherwise agreed by Dilivva,
              any fees that Dilivva may charge a User for their use of the
              Dilivva Platform (including to receive or perform any Delivery
              Services), are due immediately and are non-refundable and this no
              refund policy shall apply at all times regardless of your decision
              to terminate your usage, our decision to terminate your usage,
              disruption caused to our Platform or the Delivery Services either
              planned, accidental or intentional, or any reason whatsoever.
              Dilivva reserves the right to determine final prevailing pricing
              and any pricing information published on the Dilivva Website may
              not reflect the current pricing.
            </p>
            <p>
              Users of our Platform will be liable for any taxes (including VAT,
              if applicable) required to be paid because of the User’s use of or
              access to the Dilivva Platform, or receiving or performing any
              Delivery Services or on any Gig Payment received (other than taxes
              on the Dilivva’s income).
            </p>
            <h4>Payment, Withholding and Release</h4>
            <p>
              If chosen, a Partner is solely responsible for completing delivery
              of the Gig as agreed upon BEFORE being paid. Within 24 hours after
              a Sender receives confirmation through the Platform that their
              Partner has completed the Gig, the Sender will authorize Dilivva to
              provide the Sender’s payment details to the PSP for processing of
              Gig Payment. Dilivva, in its sole discretion, may place a hold on
              a Partner’s Gig Payment if the delivery of the Gig is not provided
              expressly in accordance with the Sender’s directions set forth in
              the request for Delivery Services, and these Terms, and/or Dilivva
              determines it otherwise necessary or advisable to place a hold on
              such Gig Payment, including without limitation if Dilivva has any
              suspicion or reason to believe that a Partner or Sender has
              breached these Terms, including, without limitation by (1) a claim
              has been made under the Dilivva Protection Plan for loss, damage
              or theft to a Gig carried by Partner, (2) sending or accepting
              items on the Prohibited Item list; (3) establishing an account
              with Dilivva or entering into a Gig with the purpose of defrauding
              Dilivva or any other party; (4) using a stolen credit card or any
              other false information to establish an account with Dilivva or
              pay for a Gig; or (5) otherwise engaging in any questionable or
              fraudulent activity in connection with the performance of the Gig
              or access and/or use of the Dilivva Platform.
            </p>
            <h4>
              Dilivva will release the Gig Payment to a Partner upon establishing
              the validity of the accounts and Gigs under suspicion, in
              Dilivva’s sole but reasonable discretion.
            </h4>
            <h4>
              DILIVVA WILL NOT RELEASE SENDER’S PAYMENT TO THE PARTNER UNTIL THE
              PERFORMANCE OF THE GIG IS COMPLETED BY THE PARTNER AS AGREED UPON
              HEREIN.
            </h4>
            <h4>Reimbursement for Parking Fees and Tolls</h4>
            <p>
              Some Senders reimburse a Partner for the cost of parking fees and
              tolls incurred while performing the Gig, but not all. If a Sender
              has agreed to reimburse Partners for parking fees and tolls, the
              Gig as posted in the Platform includes an indication that parking
              and tolls will be reimbursed and provides the Partner a place to
              enter their receipts after completing the delivery. A Partner will
              be reimbursed for parking fees and tolls incurred and paid for by
              Partner while performing a Gig ONLY if the Partner presents their
              receipts therefor (or other adequate evidence of incurring the
              same) within fifteen (15) days of incurring the expense.
            </p>
            <p>Partner Teams and Partner Personnel</p>
            <p>
              There is only one Invoice and Gig Payment per Gig. If Partners are
              in a Partner Teams, or if the Partner uses Partner Personnel to
              provide Delivery Services on his/her/its behalf, the Partner
              account assigned the Gig will receive the entire Gig Payment and
              it is the responsibility of the Partner receiving payment to divide
              among Team members and/or pay its Personnel. Once the Gig Payment
              is released to the assigned Partner account, Dilivva will have no
              further liability or responsibility to any Partner, member of a
              Partner Team, or Partner Personnel for any payment connected to such
              Gig.
            </p>
            <p>
              Notwithstanding the above, under no circumstances may a Partner be
              accompanied by (1) a minor; (2) any unnecessary/non-essential
              person, including any Partner Team member or Partner Personnel if
              not needed for the specific Delivery Services being provided; and
              (3) any other person (including Partner Personnel) while performing
              any Delivery Services containing prescription medicine or
              pharmaceuticals.
            </p>
            <p>Service Dogs</p>
            <p>
              Partners are not to have animals in their delivery vehicle,
              including Service Dogs unless such Service Dog is necessary to the
              Partner’s performance of Delivery Services. If a Partner must have a
              Service Dog present, all Gig items must be kept in a separate
              container that provides protection from pet hair and dander. In no
              event shall a Partner with a Service Dog offer to provide Delivery
              Services for Gigs containing food and food related items, clothing
              or unpackaged items. Partners should note that Service Dogs may
              cause complaints from Senders and or Gig recipients and such
              complaints may lead to the deactivation of the Partner’s account.
            </p>
            <h4>LEGAL STUFF</h4>
            <h5>
              YOU HEREBY GRANT DILIVVA PERMISSION AND THE FULL RIGHT AND
              AUTHORITY TO FULLY INVESTIGATE AND PROSECUTE VIOLATIONS OF ANY OF
              THESE TERMS (INCLUDING ALL DOCUMENTS AND AGREEMENTS REFERENCED OR
              INCORPORATED HEREIN) TO THE FULLEST EXTENT OF THE LAW, INCLUDING
              INVOLVING, COOPERATING OR DISCLOSING YOUR PERSONAL INFORMATION TO
              LAW ENFORCEMENT AUTHORITIES IN CONNECTION THEREWITH.
            </h5>
            <h5>
              WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY WITHHOLD GIG PAYMENTS,
              LIMIT, LOCK OR DEACTIVATE YOUR USER ACCOUNT, PROHIBIT ACCESS TO
              OUR PLATFORM, APPS, WEBSITES, AND THEIR CONTENT (INCLUDING YOUR
              OWN), SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE
              TECHNICAL AND LEGAL STEPS TO PREVENT ANY USER FROM ACCESSING OUR
              PLATFORM, APPS, WEBSITES OR ANY PORTION THEREOF IF WE BELIEVE THAT
              THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING
              THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING
              INCONSISTENTLY WITH THE LETTER OR SPIRIT OF THESE TERMS AND OTHER
              PUBLISHED DILIVVA TERMS OR POLICIES.
            </h5>
            <p>
              Disclaimer of Warranties and Limits on Dilivva’s Liability to You
            </p>
            <h5>
              NEITHER DILIVVA NOR ITS AFFILIATES OR LICENSORS IS RESPONSIBLE FOR
              THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF OUR
              PLATFORM AND DILIVVA AND ITS OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, REPRESENTATIVES, AFFILIATES AND LICENSORS ARE NOT BE
              LIABLE FOR ANY LOSS, CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION
              WITH YOUR USE OF THE PLATFORM OR YOUR RECEIVING OR PROVIDING
              DELIVERY SERVICES. BY USING THE PLATFORM YOU THEREBY RELEASE
              DILIVVA, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
              REPRESENTATIVES, AFFILIATES AND LICENSORS FROM ANY LIABILITY
              RELATED TO ANY USE OF OUR PLATFORM OR YOUR RECEIVING OR PROVIDING
              DELIVERY SERVICES OR THE CONDUCT OR MISCONDUCT OF A USER.
            </h5>
            <p>
              No Warranties, Express or Implied: The Delivery Services, Platform
              and App are provided to you strictly on an “as is” basis without
              warranties of any kind, either express or implied, including, but
              not limited to, warranties of merchantability, fitness for a
              particular purpose and non-infringement and all warranties are
              hereby disclaimed by Dilivva to the maximum extent permitted by
              applicable law. Dilivva makes no warranties or representations
              about the accuracy or completeness of any content provided through
              the Platform or App or the content of any websites linked to the
              Platform or App.
            </p>
            <p>
              Dilivva does not warrant, endorse, guarantee or assume
              responsibility for any service advertised or offered by a third
              party through the Platform or App or any hyperlinked website or
              featured in any banner or other advertising and Dilivva will not
              be a party to or in any way be responsible for monitoring any
              transaction between you and third-party providers of products or
              services.
            </p>
            <p>
              Without limiting the foregoing, neither Dilivva nor its affiliates
              or licensors warrant or guarantee:
            </p>
            <ul>
              <li>
                that access to the Delivery Services, Platform or App will be
                uninterrupted, error-free, secure, timely or operate on any
                Device or in combination with any other hardware, application,
                system or data;
              </li>
              <li>
                as to the results that may be obtained from the use of the
                Delivery Services, Platform or App;
              </li>
              <li>
                that the Delivery Services, Platform or App, or the quality of
                any products, services, information or other material purchased
                or obtained by you through the Platform or the App, will meet
                your requirements or expectations;
              </li>
              <li>
                as to the timeliness, accuracy, or reliability, of any User;
              </li>
              <li>
                as to the timeliness, accuracy, or reliability of our Delivery
                Services, Platform or App, or any information or materials
                provided through or in connection with the use of the Delivery
                Services, Platform or App;
              </li>
              <li>as to the completeness or content of any Gig;</li>
              <li>
                that the Platform and App are free from viruses, worms, Trojan
                horses, or other harmful components or that any errors or
                defects in the Delivery Services;
              </li>
              <li>Platform or App will be corrected; or</li>
              <li>
                that any personal information supplied by you will not be
                misappropriated, intercepted, deleted, destroyed or used by
                others.
              </li>
            </ul>
            <p>
              <b>Limitations of Liability:</b> YOU ACKNOWLEDGE AND AGREE THAT
              DILIVVA IS ONLY WILLING TO PROVIDE THE PLATFORM IF YOU AGREE TO
              CERTAIN LIMITATIONS OF OUR LIABILITY TO YOU AND THIRD PARTIES.
            </p>
            <h5>
              DILIVVA EXPRESSLY DISCLAIMS ANY LIABILITY THAT MAY ARISE BETWEEN
              USERS OF ITS PLATFORM. USE OF OUR PLATFORM, AND RECEIVING OR
              PERFORMING DELIVERY SERVICES THEREUNDER ARE ENTIRELY AT A USER’S
              OWN RISK.
            </h5>
            <h5>
              DILIVVA CURRENTLY DOES NOT AND HAS NO OBLIGATIONS TO ASSESS THE
              SUITABILITY, LEGALITY OR ABILITY OF ANY USER TO PERFORM OR
              COMPLETE A GIG AND YOU EXPRESSLY WAIVE AND RELEASE DILIVVA FROM
              ANY AND ALL ANY LIABILITY, CLAIMS OR DAMAGES ARISING FROM OR IN
              ANY WAY RELATED TO A USER’S PERFORMANCE OF A GIG. YOU ACKNOWLEDGE
              THAT USERS PROVIDING DELIVERY SERVICES REQUESTED USING THE DILIVVA
              PLATFORM OR APP MIGHT NOT BE PROFESSIONALLY LICENSED OR PERMITTED
              AND MAY NOT HAVE THE PROPER INSURANCE. DILIVVA WILL NOT BE A PARTY
              TO DISPUTES, NEGOTIATIONS OF DISPUTES BETWEEN ANY OF OUR USERS.
              YOU EXPRESSLY WAIVE AND RELEASE DILIVVA FROM ANY AND ALL
              LIABILITY, CLAIMS, CAUSES OF ACTION, OR DAMAGES ARISING FROM YOUR
              ACCESS OR USE OF THE DILIVVA PLATFORM, APP OR YOUR RECEIVING OR
              PERFORMING DELIVERY SERVICES, OR IN ANY WAY RELATED TO ANOTHER
              USER OR ANY OTHER THIRD PARTY INTRODUCED TO YOU BY THE PLATFORM,
              APP OR DURING A GIG, OR OTHERWISE.
            </h5>
            <h5>
              YOU AGREE NOT TO HOLD DILIVVA, ITS AFFILIATES, ITS LICENSORS, ITS
              PARTNERS IN PROMOTIONS, SWEEPSTAKES OR CONTESTS, OR ANY OF SUCH
              PARTIES’ AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE
              PARTNERS, OR PARTICIPANTS LIABLE FOR ANY DAMAGE, SUITS, CLAIMS,
              AND/OR CONTROVERSIES (COLLECTIVELY, “LIABILITIES”) THAT HAVE
              ARISEN OR MAY ARISE, WHETHER KNOWN OR UNKNOWN, RELATING TO YOUR OR
              ANY OTHER PARTY’S USE OF OR INABILITY TO ACCESS OR USE THE
              PLATFORM, APP OR DELIVERY SERVICES, INCLUDING WITHOUT LIMITATION
              ANY LIABILITIES ARISING IN CONNECTION WITH THE CONDUCT, ACT OR
              OMISSION OF ANY USER (INCLUDING WITHOUT LIMITATION STALKING,
              HARASSMENT THAT IS SEXUAL OR OTHERWISE, ACTS OF PHYSICAL VIOLENCE,
              AND DESTRUCTION OF PERSONAL PROPERTY), ANY DISPUTE WITH ANY USER,
              ANY INSTRUCTION, ADVICE, ACT, OR SERVICE PROVIDED BY DILIVVA OR
              ITS AFFILIATES OR LICENSORS AND ANY DESTRUCTION OF YOUR
              INFORMATION AND WHETHER OR SUCH CONDUCT, ACT OR OMISSION OCCURS
              DURING YOUR USE OR ACCESS OF THE PLATFORM OR APP OR DURING THE
              PERFORMANCE OF A GIG.
            </h5>
            <h5>
              UNDER NO CIRCUMSTANCES WILL DILIVVA, ITS AFFILIATES, ITS
              LICENSORS, OR ANY OF SUCH PARTIES’ AGENTS, EMPLOYEES, OFFICERS,
              DIRECTORS, CORPORATE PARTNERS, OR PARTICIPANTS BE LIABLE TO YOU,
              ANOTHER USER OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
              CONSEQUENTIAL, PUNITIVE, SPECIAL OR EXEMPLARY DAMAGES ARISING IN
              CONNECTION WITH YOUR USE OF OR INABILITY TO ACCESS OR USE THE
              PLATFORM, APP OR DELIVERY SERVICES, ANY RELIANCE PLACED BY YOU ON
              THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS
              A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN A PARTNER,
              SENDER, ANY THIRD PARTY SERVICE PROVIDER, ADVERTISER OR SPONSOR
              WHOSE ADVERTISING APPEARS ON THE WEBSITE OR IS A USER OF THE
              PLATFORM OR APP INCLUDING FOR PERSONAL INJURY, LOSS OF DATA,
              REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE, EVEN IF ALL
              PARTIES HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES.
            </h5>
            <h5>
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
              OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY
              TO YOU.
            </h5>
            <h5>
              IF NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT
              DILIVVA OR ITS AFFILIATES, ITS LICENSORS, OR ANY OF SUCH PARTIES’
              AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE PARTNERS, OR
              PARTICIPANTS ARE LIABLE FOR DAMAGES, IN NO EVENT WILL THE
              AGGREGATE LIABILITY, WHETHER ARISING IN CONTRACT, TORT, STRICT
              LIABILITY OR OTHERWISE, EXCEED THE TOTAL FEES PAID BY YOU OR TO
              YOU DURING THE SIX MONTHS PRIOR TO THE TIME SUCH CLAIM AROSE
            </h5>
            <h5>
              YOU EXPRESSLY WAIVE AND RELEASE ANY AND ALL RIGHTS AND BENEFITS
              UNDER SECTION 1542 OF THE CIVIL CODE OF THE STATE OF CALIFORNIA
              (OR ANY ANALOGOUS LAW OF ANY OTHER STATE), WHICH READS AS FOLLOWS:
              “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
              DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
              EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM, MUST HAVE
              MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
            </h5>
            <h4>Intellectual Property</h4>
            <p>
              Ownership: Dilivva owns all intellectual property rights in and to
              the Platform and the App, including but not limited to database
              rights, copyright, design rights, trademarks and other similar
              rights, whether or not currently registered, wherever existing in
              the world together, with full rights to apply for protection of
              the same, and your use of the Platform or App gives you no rights
              therein.
            </p>
            <p>
              Access and Use of the Dilivva Platform and App: Dilivva owns and
              retains sole ownership in the Dilivva Platform and App, and all
              intellectual property and proprietary rights therein. Contingent
              upon your compliance with the terms and conditions of the Terms,
              Dilivva hereby grants to you the limited right to access and use
              the Platform via the App on any authorized Android Device that you
              own or control and/or any iPhone or iPod touch that you own or
              control and as permitted by the Usage Rules set forth in Section
              9.b. of the App Store Terms and Conditions (the “Usage Rules“).
              This grant does not allow you to use the Platform or App on any
              Device that you do not own or control. The terms of this grant
              will govern any upgrades provided by Dilivva that replace and/or
              supplement the Dilivva Platform or App, unless such upgrade is
              accompanied by a separate grant in which case the terms of that
              license will govern.
            </p>
            <p>
              Dilivva reserves the right to stop offering and/or supporting our
              Platform, App or our Users’ ability to receive or perform Delivery
              Services or any particular portion or part of our Platform, App or
              access to Delivery Services at any time, at which point your right
              to access and use the Platform or App and your access to the
              Delivery Services therefrom, or any part thereof, will be
              automatically deactivated. In such event, Dilivva shall not be
              required to provide refunds or other compensation to Users in
              connection with such discontinued Platform, App or access to
              Delivery Services.
            </p>
            <p>
              You shall not (i) license, sublicense, sell, resell, transfer,
              assign, distribute or otherwise commercially exploit or make
              available to any third party the Platform or the App in any way;
              (ii) modify or make derivative works based upon the Platform or
              the App; (iii) create Internet “links” to the Platform, alternate
              access to Delivery Services, or “frame” or “mirror” any App on any
              other server or wireless or Internet-based device; (iv) reverse
              engineer or access the Platform or App to copy any ideas,
              features, functions or graphics of the Platform or App whether to
              build competitive products or services using similar ideas,
              features, functions or graphics of the Platform or App, or
              otherwise; (v) launch an automated program or script that unduly
              burdens, interferes, disrupts, hinders the integrity, operation
              and/or performance of the Platform or App (or the data contained
              therein) in any way or for any User, including but not limited to
              Trojan horses, viruses, worms, web spiders, web crawlers, web
              robots, web ants, web indexers, bots, or any program which may
              make multiple server requests per second, or send and/or store in
              our App; (vi) attempt to gain unauthorized access to the Platform
              or App or its related systems or networks, or circumvent or
              attempt to defeat any security or verification measures relating
              to use of the Platform or App; or (vii) use our Platform or App to
              send spam or otherwise duplicative or unsolicited messages, send
              or store infringing, obscene, threatening, libelous, or otherwise
              unlawful or tortious material, including material harmful to
              children or that violates third party privacy rights or send or
              store material containing software viruses, worms, Trojan horses
              or other harmful computer code, files, scripts, agents or
              programs.
            </p>
            <p>
              Licenses Granted by Dilivva in Content: Subject to your compliance
              with the Terms, including without limitation Partner and Sender
              Agreements, Dilivva grants you a limited, non-exclusive,
              non-transferable/sublicensable, non-assignable license: (i) to
              view, download and print any Content that Dilivva makes available
              through the Platform or App, including any Content licensed from a
              third party (“Dilivva Content“), but excluding any Content that a
              User posts, uploads, publishes, submits or transmits to be made
              available through the Platform or App, including any questions,
              comments, suggestions, ideas, or feedback (“User Content” and with
              Dilivva Content collectively, “Collective Content“); and (ii) to
              view any User Content to which you are permitted access solely for
              your personal and non-commercial purposes.
            </p>
            <p>
              Dilivva Content may be used solely for your personal and
              non-commercial purposes. You will not use, copy, adapt, modify,
              prepare derivative works based upon, distribute, license, sell,
              transfer, publicly display, publicly perform, transmit, stream,
              broadcast or otherwise exploit the Platform, App or Collective
              Content, except as expressly permitted in the Terms. No licenses
              or rights are granted to you by implication or otherwise under any
              intellectual property rights owned or controlled by Dilivva or its
              licensors, and are only valid if expressly granted in the Terms.
            </p>
            <p>
              You agree that Dilivva has no responsibility to, and may not,
              monitor your access to or use of Collective Content or review or
              edit any Collective Content. Dilivva reserves the right, at any
              time and without prior notice, to remove or disable access to any
              Collective Content that Dilivva, at its sole discretion, considers
              to be in violation of these Terms or otherwise harmful.
            </p>
            <p>
              License Granted by User: We may, in our sole discretion, permit
              Users to post, upload, publish, submit or transmit User Content.
              By making available or posting any User Content on or through the
              Platform or App, you hereby grant to Dilivva a worldwide,
              irrevocable, perpetual, exclusive, transferable, royalty-free
              license, with the right to sublicense, to use, view, copy, adapt,
              modify, distribute, license, sell, transfer, publicly display,
              publicly perform, transmit, stream, broadcast, disseminate and
              otherwise exploit such User Content. Dilivva shall be entitled to
              the unrestricted use of all User Content for any purpose,
              commercial or otherwise, without acknowledgment or compensation to
              you. Dilivva claims no ownership rights in any User Content and
              nothing in the Terms will be deemed to restrict any rights that
              you may have to use and exploit your User Content.
            </p>
            <p>
              You acknowledge and agree that you are solely responsible for all
              User Content that you make available through the Platform or App.
              By posting or making available any User Content, you represent and
              warrant that: (i) you are the sole and exclusive owner of all User
              Content that you make available or post or you have all right,
              license, consent and release that are required to grant to Dilivva
              full rights in such User Content, as contemplated under the Terms;
              and (ii) neither the User Content nor your posting, uploading,
              publication, submission or transmittal of the User Content or
              Dilivva’s use of the User Content (or any portion thereof) will
              infringe, misappropriate or violate a third party’s patent,
              copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy,
              or result in the violation of any applicable law or regulation.
            </p>
            <p>
              Copyright Policy: Dilivva respects copyright law and the
              intellectual property of others and expects its Users to do the
              same. Dilivva will deactivate a User’s account that infringes or
              is reasonably believed to be infringing the rights of copyright
              holders. If you believe, in good faith, that any materials on our
              App or Website infringe upon your copyrights, please send the
              following information to Dilivva’s Copyright Agent at
              info@dilivva.com:
            </p>
            <ul>
              <li>
                A description of the copyrighted work that you claim has been
                infringed, including sufficient information to locate the
                specific place on our App or Website where the material is
                located. Please include sufficient information to locate the
                material and explain why you think an infringement has taken
                place;
              </li>
              <li>
                A complete description of the location where the original or an
                authorized copy of the copyrighted work exists; an Internet
                address where the work is posted or the name of the book in
                which it was published;
              </li>
              <li>Your address, telephone number, and e-mail address;</li>
              <li>
                A signed statement by you that, in good faith, you believe that
                the disputed use is not authorized by the copyright owner, its
                agent, or law;
              </li>
              <li>
                A statement by you, made under penalty of perjury, that the
                information in your notice is accurate, and that you are the
                copyright owner or authorized to act on the copyright owner’s
                behalf; and
              </li>
              <li>
                An electronic or physical signature of the owner of the
                copyright or the person authorized to act on behalf of the owner
                of the copyright interest.
              </li>
            </ul>
            <h4>Other Third Party Interactions</h4>
            <p>
              Via the Dilivva Platform, including on our Website, you may enter
              into correspondence with, purchase goods and/or services from, or
              participate in promotions of third party service providers,
              advertisers or sponsors showing their goods and/or services
              through the Website or App. Any such activity, and any terms,
              conditions, warranties or representations associated with such
              activity, is solely between you and the applicable third-party.
              Dilivva and its licensors shall have no liability, obligation or
              responsibility for any such correspondence, purchase, transaction
              or promotion between you and any such third-party. Dilivva does
              not endorse any sites on the Internet that are linked through the
              Website or App, and in no event shall Dilivva or its licensors be
              responsible for any content, products, services or other materials
              on or available from such sites or third party providers. Dilivva
              provides the Website and App to you pursuant to these Terms and
              certain third-party providers of goods and/or services may require
              your agreement to additional or different terms and conditions
              prior to your use of or access to such goods or services. Dilivva
              has no responsibility or liability arising from any agreements
              between you and such third party providers.
            </p>
            <p>
              Dilivva may rely on third party advertising and marketing supplied
              through the Website or App and other mechanisms to subsidize the
              Website or App. By agreeing to these terms and conditions you
              agree to receive such advertising and marketing. If you do not
              want to receive such advertising you should notify us in writing.
              Dilivva reserves the right to charge you a higher fee for access
              to the Platform should you choose not to receive these advertising
              services, if offered. This higher fee, if applicable, will be
              posted on Dilivva’s Website located at{" "}
              <a href="https://www.dilivva.com">https://www.dilivva.com</a>
            </p>
            .<h4>SMS Messaging</h4>
            <p>
              All of our Users are automatically opted-in to receive SMS
              messaging when signing up for our Platform and must agree to
              receive SMS messages in order to use our Platform and receive or
              provide Delivery Services via the Platform and data rates may
              apply. If you change your mobile phone service provider all SMS
              messaging services from Dilivva may be deactivated. Dilivva
              reserves the right to cancel SMS messaging services at any time.
            </p>
            <h4>Notices</h4>
            <p>
              Dilivva may give Users general notice regarding the Platform,
              these Terms, Partners or Senders Agreements, or otherwise via
              electronic mail to your email address on record in Dilivva’s
              account information, or by written communication sent by first
              class mail or pre-paid post to your address on record in Dilivva’s
              account information. Any legal or formal notices, requests or
              other communications required or permitted to be given hereunder
              shall be in writing and shall be delivered by hand, by overnight
              courier, mailed by United States registered or certified mail,
              return receipt requested, postage prepaid, and addressed to the
              appropriate party at its address set forth below.
            </p>
            <p>
              Such notice shall be deemed to have been given upon the expiration
              of 48 hours after mailing or posting (if sent by first class mail
              or pre-paid post) or 12 hours after sending (if sent by email).
              You may give notice to Dilivva (such notice shall be deemed given
              when received by Dilivva) at any time by email at
              operations@dilivva.com with a copy to legal@dilivva.com and/or
              letter delivered by nationally recognized overnight delivery
              service or first class postage prepaid mail to Dilivva, Inc. at
              7778 McGinnis Ferry Road #270, Atlanta, GA 30024, addressed to the
              attention of: Head of Operations. Dilivva will give notice to
              Partner at the address provided by Partner when forming their
              account on the Dilivva Platform.
            </p>
            <h3>Assignment</h3>
            <p>
              You may not assign your responsibilities, duties and obligations
              (or any portion thereof) as set forth in these Terms, including in
              any Partner or Sender Agreement, as applicable, to any party
              without the prior written approval of Dilivva in each instance.
              Any purported assignment in violation of this section shall be
              void.
            </p>
            <h4>Export Control</h4>
            <p>
              You agree to comply fully with all U.S. and foreign export laws
              and regulations to ensure that none of the Platform, App, any
              technical data related thereto or any direct product thereof is
              exported or re-exported directly or indirectly in violation of, or
              used for any purposes prohibited by, such laws and regulations. By
              using the Platform, you represent and warrant that: (i) you are
              not located in a country that is subject to a U.S. Government
              embargo, or that has been designated by the U.S. Government as a
              “terrorist supporting” country; and (ii) you are not listed on any
              U.S. Government list of prohibited or restricted parties.
            </p>
            <h3>RELATIONSHIP BETWEEN DILIVVA, PARTNER AND SENDERS</h3>
            <p>
              No joint venture, partnership, employment, or agency relationship
              exists between you, any other User, Dilivva or any third party
              provider as a result of a User entering into a Partner or Sender
              Agreement, their use of the Platform or their providing or
              receiving Delivery Services via the Platform. If any provision of
              these Terms or any Partners or Senders Agreement is held to be
              invalid or unenforceable, such provision shall be revised as
              minimally as possible to validate or enforce the same, as well as
              the original intent of these Terms, and the remaining provisions
              shall be enforced to the fullest extent under law. The failure of
              Dilivva to enforce any right or provision in these Terms, any
              Partner or Sender Agreement shall not constitute a waiver of such
              right or provision unless specifically acknowledged and agreed to
              by an authorized person from Dilivva in writing. Unless Dilivva
              has entered into a separate written agreement with a User
              addressing their access or use of our Platform or App or the
              Delivery Services, these Terms, and all agreements and documents
              referenced herein, including without limitation the Partners and
              Senders Agreements, comprise the entire agreement between our
              Users and Dilivva superseding all prior or contemporaneous
              negotiations, discussions or agreements, whether written or oral,
              between a User and Dilivva regarding a User’s use of and access to
              the Dilivva Platform, Website, App and Delivery Services.
            </p>
            <p>
              If you have entered into another Agreement with Dilivva and/or any
              Dilivva affiliate you acknowledge that document also is part of
              our agreement regarding your use of the Dilivva Platform.
            </p>
            <p>
              If you have entered into a Dispute Resolution and/or Arbitration
              Agreement with Dilivva, and/or any Dilivva affiliate, you
              acknowledge that those documents are also a part of our agreement
              regarding your use of the Dilivva Platform.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
