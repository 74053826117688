import { useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Link } from "react-router-dom";
import faqs from "../assets/images/faqs.svg";
import logo from "../assets/images/dilivva-icon.jpg";

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 py-5">
              <h1 className="mb-5 fw-bold">
                Frequently Asked <br />
                <span className="time">Questions</span>
              </h1>
              <p className="content">
                Get answers to some of our <br /> most common questions.
              </p>
            </div>
            <div className="col-md-6">
              <img src={faqs} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="accordion-section pb-5">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-8 pt-8">
                    <div className="text-center">
                      <h4 className="my-5">Sender</h4>
                    </div>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            What states are currently available?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Dilivva is currently available in Lagos, Oyo, Ogun,
                            Ondo and FCT. More states will be activated soon
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            What if I have multiple packages?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            If you have multiple packages that need to be
                            shipped, you would need to create separate shipments
                            for each package. Unfortunately, it is not possible
                            to create multiple packages within a single
                            shipment. This is because each package needs its own
                            unique tracking information and shipping label. To
                            ensure that each package is properly tracked and
                            delivered, it is necessary to create a separate
                            shipment for each package. If you have any concerns
                            or questions, please let us know and we will be
                            happy to assist you.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            How quickly will my package be delivered?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Your package will be delivered on the same day as
                            your order.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFive">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                          >
                            What hours are your services open?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Our services are open 24/7, however, please note
                            that delivery times may vary depending on the
                            availability of our delivery agents.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSix">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix"
                            aria-expanded="false"
                            aria-controls="flush-collapseSix"
                          >
                            Can I make request on the website?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSix"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Not at the moment. You’ll have to download the app
                            on AppStore or PlayStore to create a package for
                            delivery.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingSeven"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeven"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeven"
                          >
                            Who to contact if my package hasn’t been delivered
                            in 24hrs?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSeven"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSeven"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            If your package hasn't been delivered within 24
                            hours, you can contact us for assistance. Please
                            send us an email on support@dilivva.com or click the
                            WhatsApp icon on the website to chat with us with
                            your order number and details about the delivery,
                            and we will investigate and update you on the status
                            of your package. Our customer service team will be
                            more than happy to help you out.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingEight"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEight"
                            aria-expanded="false"
                            aria-controls="flush-collapseEight"
                          >
                            Do I have to pay the person that delivers my package
                            again?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEight"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingEight"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            No. Payment is made upfront and no additional
                            payment is required.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingNine">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseNine"
                            aria-expanded="false"
                            aria-controls="flush-collapseNine"
                          >
                            How can I track my package?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseNine"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingNine"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            To track your package, you can use our app. First,
                            download the app from the App Store or Google Play.
                            Once you have the app installed, log in to your
                            account. From there, you will be able to see all of
                            your current and past shipments, as well as
                            real-time tracking information for each package. You
                            will also receive notifications on the status of
                            your shipment. If you have any issues using the app
                            or tracking your package, please contact our
                            customer service team for assistance.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingEleven"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEleven"
                            aria-expanded="false"
                            aria-controls="flush-collapseEleven"
                          >
                            How does Dilivva delivery service work?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEleven"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingEleven"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Users of the service can request a delivery by
                            posting a delivery request on the platform, which
                            includes the pickup and drop-off locations and any
                            other details about the delivery. Other users of the
                            platform, known as "delivery partners," can then see
                            the delivery request and choose to fulfill it by
                            picking up and delivering the item. Payment for the
                            delivery is processed through the platform, and the
                            delivery partner is compensated for their time and
                            effort.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThirteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThirteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseThirteen"
                          >
                            Is the delivery service only for small packages?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThirteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThirteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            The service can be used to deliver a wide range of
                            items, including small packages, groceries, and even
                            larger items like furniture. However, it's important
                            to note that delivery partners are individuals using
                            their own vehicles, so the size and weight of the
                            item to be delivered may be a factor in whether or
                            not it can be transported.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingFourteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFourteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFourteen"
                          >
                            What happens if an item is lost or damaged during a
                            delivery?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFourteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFourteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Dilivva includes insurance coverage for lost or
                            damaged items. If an item is lost or damaged during
                            a delivery, users of the service can file a claim
                            through the platform and may be eligible for
                            compensation. (terms and conditions apply)
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingSeventeen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeventeen"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeventeen"
                          >
                            Is it safe to use Dilivva?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSeventeen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSeventeen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            The safety of the service is a top priority for the
                            platform and the delivery partners. All delivery
                            partners are required to undergo a background check
                            before they can begin delivering items, and the
                            service includes insurance coverage for lost or
                            damaged items.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingEighteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEighteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseEighteen"
                          >
                            Can I request a delivery for items that are illegal
                            or prohibited by the platform's terms of service?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEighteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingEighteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            No, it is not allowed to request a delivery for
                            illegal or prohibited items. The platform's terms of
                            service outline what types of items are not allowed
                            to be delivered, and requesting a delivery for such
                            items may result in the termination of a user's
                            account.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingNineteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseNineteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseNineteen"
                          >
                            How do I contact Dilivva if I have an issue with a
                            delivery or a delivery partner?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseNineteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingNineteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            The platform should have a customer support team
                            available to assist users with any issues that may
                            arise during a delivery. Users can typically contact
                            the support team through the app or website, or by
                            email or phone if those options are available. It's
                            important to raise any issues as soon as possible so
                            that they can be addressed in a timely manner.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingFifteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFifteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFifteen"
                          >
                            Are there prohibited items?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFifteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFifteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Kindly visit:{" "}
                            <Link to="../prohibited-items">
                              https://dilivva.com/prohibited-items/
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="accordion-section pb-5">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-8 pt-8">
                    <div className="text-center">
                      <h4 className="my-5">Partner</h4>
                    </div>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading1">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse1"
                            aria-expanded="false"
                            aria-controls="flush-collapse1"
                          >
                            How to become a dilivva partner?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse1"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-heading1"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Download and signup To become a Dilivva partner, you
                            will need to download the Dilivva Partner app and
                            onboard yourself.
                            <ol className="mt-3">
                              <li>
                                First, visit the Dilivva website or the app
                                store on your device and download the app.
                              </li>
                              <li>
                                Once the app is installed, open it and create an
                                account by providing your personal details.
                              </li>
                              <li>
                                Fill in the required fields and submit the
                                necessary documentation, such as proof of
                                identity and other relevant licenses.
                              </li>
                              <li>
                                After submitting your application, Dilivva's
                                team will review your application and will get
                                back to you as soon as possible.
                              </li>
                            </ol>
                            Please note that the process of becoming a Dilivva
                            partner may vary depending on your location and
                            other factors, and the above steps are just a
                            general overview of the process. I recommend
                            checking Dilivva's website or reaching out to
                            Dilivva's customer support team for more information
                            on the specific requirements and processes in your
                            area.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading2">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse2"
                            aria-expanded="false"
                            aria-controls="flush-collapse2"
                          >
                            What Are Partner Certifications and How Are They
                            Achieved?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse2"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-heading2"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            The specific requirements for Partner certifications
                            vary depending on the mode of transportation. For
                            example, truck drivers are typically required to
                            have a driver's license while motorcycle Partners may
                            be required to have a different license. <br />{" "}
                            However, despite the differences in certifications,
                            there are some standard requirements. These include
                            a valid passport, driver's license, voter's card,
                            and National ID card. These documents are typically
                            required to verify the partner’s identity.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading3">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse3"
                            aria-expanded="false"
                            aria-controls="flush-collapse3"
                          >
                            How and when do I get paid?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse3"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-heading3"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            You will get paid on Mondays by 12noon. Typically,
                            payments are made via direct deposit to the bank
                            account you have provided.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading4">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse4"
                            aria-expanded="false"
                            aria-controls="flush-collapse4"
                          >
                            List of prohibited items?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse4"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-heading4"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Kindly visit:{" "}
                            <Link to="../prohibited-items">
                              https://dilivva.com/prohibited-items/
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading5">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse5"
                            aria-expanded="false"
                            aria-controls="flush-collapse5"
                          >
                            What is expected of me as a partner?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse5"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-heading5"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Kindly visit:{" "}
                            <Link to="../partner-agreement">
                              https://dilivva.com/partner-agreement/
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





























      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="accordion-section pb-5">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-8 pt-8">
                    <div className="text-center">
                      <h4 className="my-5">Courier Companies</h4>
                    </div>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            How many dispatchers can I register?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                          There is no limit to how many dispatchers you can register.
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            How/when do I get paid
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                          you will be credited into your wallet, and you can withdraw at any time
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Are my dispatchers paid directly
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            No they are not. The Courier Wallet would be credited, and accessible on demand only by the Administrator.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFive">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                          >
                            What license/documents do I need to register
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            The courier service needs to provide:
                            <ol>
                              <li>Company Identification Document</li>
                              <li>Contact Person Identification</li>
                              <li>Utility Bill</li>
                            </ol>
                            Every dispatcher needs to provide:
                            <ol>
                              <li>Drivers license</li>
                              <li>Personal identification details</li>
                              <li>Vehicle particulars</li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSix">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix"
                            aria-expanded="false"
                            aria-controls="flush-collapseSix"
                          >
                            How do I assign jobs to a particular dispatcher
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSix"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            You assign Jobs to a particular dispatcher by logging into your dashboard at courier.dilivva.com , where you would be able to see a list of available orders and assign them to your available dispatcher. Please watch the following video for more explanation on how to navigate through the courier admin dashboard.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingSeven"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeven"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeven"
                          >
                            Is there insurance for damaged or lost packages?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSeven"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSeven"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                          Dilivva includes insurance coverage for lost or damaged items. If an item is lost or damaged during a delivery, users of the service can file a claim through the platform and may be eligible for compensation. (terms and conditions apply).
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingEight"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEight"
                            aria-expanded="false"
                            aria-controls="flush-collapseEight"
                          >
                            Can my dispatcher pick up packages independently?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEight"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingEight"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Yes, every dispatcher is able to see available packages within their vicinity and they can choose to accept any.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingNine">
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseNine"
                            aria-expanded="false"
                            aria-controls="flush-collapseNine"
                          >
                            How do I know the nature of the package
                          </button>
                        </h2>
                        <div
                          id="flush-collapseNine"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingNine"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            A detailed description of every package is made available on the app once the job is accepted.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingEleven"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseEleven"
                            aria-expanded="false"
                            aria-controls="flush-collapseEleven"
                          >
                            Can I remove a dispatcher at any time I choose?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseEleven"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingEleven"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            Yes, you can remove a dispatcher at any point in time you decide to.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThirteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThirteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseThirteen"
                          >
                            What happens in the situation where contact at the drop-off location is unavailable?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThirteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThirteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                          An alternate address is provided on each order where packages can be dropped off incase of situations where the contact at the drop of location is unavailable.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingFourteen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFourteen"
                            aria-expanded="false"
                            aria-controls="flush-collapseFourteen"
                          >
                            What kind of vehicles can be registered?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFourteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFourteen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                          You can register either a Motorcycle, truck or Car. There are different sizes of packages on our platform and each size has a vehicle that is allowed to pick it.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingSeventeen"
                        >
                          <button
                            className="accordion-button collapsed text-black shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeventeen"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeventeen"
                          >
                            How do I report a complaint?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSeventeen"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSeventeen"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body text-black">
                            You can contact us via any of our social media platforms, or send an e-mail to support@dilivva.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
































      <FloatingWhatsApp
        phoneNumber="+2349158867851"
        accountName="Dilivva"
        avatar={logo}
      />
    </>
  );
};

export default Faqs;
